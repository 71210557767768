import React from 'react'

import Page from '../../components/page'
import withRoot from '../../withRoot'

import {
  Typography,
  Paper,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(3),
  },
})

@withRoot
@withStyles(styles)
export default class Limitations extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Page toggleTheme={this.props.toggleTheme} title="Limitations">
        <Typography>
          We&apos;re doing our best to provide the best user experience for designers and users with our graphical editor.<br />
          Any problem you can see in the web editor is our responsibility and we will fix it :-)<br />
          But if you have problem with your design on the device, it is probably due to a bug in the Fitbit OS and unfortunately we will not be able to fix it easily.
        </Typography>
        <Paper className={classes.paper}>
          <Table className={classes.table} size="small">
            <TableBody>
              <TableRow>
                <TableCell align="left" colSpan={2}><h3>Gradients Issues</h3></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"><Chip label="Problem" className={classes.chip} variant="outlined" color="secondary" /></TableCell>
                <TableCell className={classes.premium} align="left">The gradients with rotation are not displayed on device.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"><Chip label="No solution" className={classes.chip} variant="outlined" /></TableCell>
                <TableCell className={classes.premium} align="left">
                  The lastest Fitbit OS broke the gradients with rotation.<br />
                  Don&apos;t use gradients with rotation until the problem is fixed by Fitbit.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" colSpan={2}><h3>Mask Issues</h3></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"><Chip label="Problem" className={classes.chip} variant="outlined" color="secondary" /></TableCell>
                <TableCell className={classes.premium} align="left">Circles in mask don&apos;t work as expected, they have no effect.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"><Chip label="No solution" className={classes.chip} variant="outlined" /></TableCell>
                <TableCell className={classes.premium} align="left">
                  The lastest Fitbit OS broke the circles in masks.<br />
                  Don&apos;t use circles in masks until the problem is fixed by Fitbit.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" colSpan={2}><h3>Custom fonts Issues</h3></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"><Chip label="Problem" className={classes.chip} variant="outlined" color="secondary" /></TableCell>
                <TableCell className={classes.premium} align="left">When I use a lot of text elements with custom font, the design is not loaded on the watch.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"><Chip label="Solution" className={classes.chip} variant="outlined" /></TableCell>
                <TableCell className={classes.premium} align="left">
                  The custom fonts are not natively supported by Fitbit OS.<br />
                  We have implemented our own solution and it is working very well.<br />
                  But it consumes memory and you can&apos;t have too much elements or your watch will not be able to load everything.<br />
                  Try to reduce the number of elements using the custom fonts and replace static texts with images if possible.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left" colSpan={2}><h3>Image Issues</h3></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"><Chip label="Problem" className={classes.chip} variant="outlined" color="secondary" /></TableCell>
                <TableCell className={classes.premium} align="left">When I rotate an image, it is cropped on the right side or I see strange artefacts.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left"><Chip label="Solution" className={classes.chip} variant="outlined" /></TableCell>
                <TableCell className={classes.premium} align="left">
                  There are known problems when you rotate big images on the device.<br />
                  Don&apos;t rotate images larger than 200x200 pixels.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Typography>
          If you have other problems, contact us and we will do our best to find a solution.
        </Typography>
      </Page>
    )
  }
}
